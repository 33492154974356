<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <el-select v-model="plate" placeholder="搜索板块" clearable filterable @change="fetchData">
        <el-option v-for="item in plateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-cascader
        v-model="category"
        placeholder="搜索类目"
        :options="categoryList"
        :props="{ label: 'name', value: 'id', checkStrictly: true }"
        clearable
        filterable
        @change="fetchData"
      ></el-cascader>
      <span>- 商品库存货值分析</span>
    </div>
    <div class="summary">
      <div class="summary-item">
        <div class="summary-item-title">销售金额</div>
        <div class="summary-item-number">{{_pennyToYuan(stock.orderAmount)}}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">销售利润</div>
        <div class="summary-item-number">{{_pennyToYuan(stock.sellFee)}}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">当前剩余库存</div>
        <div class="summary-item-number">{{stock.stock}}</div>
      </div>
      <div class="summary-item" style="border: none;">
        <div class="summary-item-title">当前剩余货值</div>
        <div class="summary-item-number">{{_pennyToYuan(stock.costPrice)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/api/axios.js'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      day: 30,
      plate: '',
      plateList: [],
      category: [],
      categoryList: [],
      stock: {}
    }
  },
  created () {
    this.getPlate()
    this.getCategory()
    this.onTabChange(30)
  },
  methods: {
    async getPlate () {
      const res = await axios.get('/management/plate/list/tree')
      this.plateList = res.list
    },
    async getCategory () {
      const res = await axios.get('/management/categories/tree')
      this.categoryList = res.list
    },
    async fetchData () {
      const cLength = this.category.length
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        plateId: this.plate,
        categoryId: cLength > 0 ? this.category[cLength - 1] : ''
      }
      const res = await axios.get('/management/order/statistics-goods', { params })
      this.stock = res
    },
    onTabChange (day) {
      const { startDate, endDate } = getDateRange(day)
      this.startDate = startDate
      this.endDate = endDate
      this.fetchData()
    },
    async onDateChange (date) {
      const { startDate, endDate } = fmtDataRange(date)
      this.startDate = startDate
      this.endDate = endDate
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #ffffff;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d9e0f0;
    span {
      color: #999999;
      margin-right: 20px;
    }
  }

  .summary {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #ffffff;
    height: 140px;
    &-item {
      height: 100%;
      display: grid;
      align-content: center;
      text-align: center;
      border-right: 1px solid #d9e0f0;
      &-title {
        font-size: 16px;
        color: #7d7e8e;
      }
      &-number {
        font-size: 32px;
        font-weight: bold;
        color: #505162;
        line-height: 42px;
      }
    }
  }
}
</style>
