<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <span>- 商品销售分析</span>
    </div>
      <div class="card-chart">
        <ve-bar :data="numberChartData" :extend="extend" :settings="settings" :colors="colors"></ve-bar>
        <div class="card-chart-title">单品销量前十</div>
      </div>
      <div class="card-chart">
        <ve-bar :data="moneyChartData" :extend="extend" :settings="settings" :colors="amountColors"></ve-bar>
        <div class="card-chart-title">单品销售金额前十</div>
      </div>
    <!-- <div class="card-table">
      <el-table
        :data="showData"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="类目名称"
        >
        </el-table-column>
        <el-table-column
          prop="sellCount"
          label="销售数量"
        >
        </el-table-column>
        <el-table-column
          prop="countRate"
          label="数量比例"
        >
        </el-table-column>
        <el-table-column
          prop="sellAmount"
          label="销售金额"
        >
        </el-table-column>
        <el-table-column
          prop="amountRate"
          label="金额比例"
        >
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div> -->
  </div>
</template>

<script>
import * as goodStatistics from '@/api/goodStatistics'
import * as R from 'ramda'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      data: [],
      total: 10,
      pageSize: 8,
      currentPage: 1,
      numberChartData: {
        columns: ['name', 'sellCount'],
        rows: []
      },
      moneyChartData: {
        columns: ['name', 'sellAmount'],
        rows: []
      },
      settings: {
        labelMap: {
          sellCount: '销量',
          sellAmount: '销售额'
        }
      },
      colors: ['#1D90FB'],
      amountColors: ['#F13A30'],
      extend: {
        legend: {
          show: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    showData () {
      return R.slice((this.currentPage - 1) * (this.pageSize), this.currentPage * this.pageSize, this.data)
    }
  },
  methods: {
    async fetchData (date = 30) {
      const [amount, count] = await Promise.all([
        goodStatistics.amount(getDateRange(date)),
        goodStatistics.count(getDateRange(date))
      ])
      this.handleData(amount, count)
    },
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.currentPage = val
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const [amount, count] = await Promise.all([
        goodStatistics.amount(fmtDataRange(data)),
        goodStatistics.count(fmtDataRange(data))
      ])
      this.handleData(amount, count)
    },
    handleData (amountData, countData) {
      this.numberChartData.rows = countData.map(o => {
        o.name = o.name.substring(0, 50)
        return o
      }).reverse()
      this.moneyChartData.rows = amountData.map(o => {
        o.name = o.name.substring(0, 50)
        o.sellAmount = this._pennyToYuan(o.sellAmount)
        return o
      }).reverse()
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #FFFFFF;
  margin: 20px auto;
  padding-bottom: 20px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #D9E0F0;

    span {
      color: #999999;
      margin-right: 20px;
    }
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7D7E8E;
      line-height: 25px;
    }
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
  }
}

</style>
