<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <span>- 商品板块销售分析</span>
    </div>
    <div class="card-content">
      <div class="card-chart">
        <ve-pie
          :data="numberChartData"
          :settings="numberChartSettings"
          :extend="extend"
        ></ve-pie>
        <div class="card-chart-title">板块销售占比</div>
      </div>
      <div class="card-chart">
        <ve-pie
          :data="moneyChartData"
          :settings="moneyChartSettings"
          :extend="extend"
        ></ve-pie>
        <div class="card-chart-title">板块销售金额占比</div>
      </div>
      <div class="card-table">
        <el-table :data="showData" style="width: 100%">
          <el-table-column prop="name" label="类目名称"> </el-table-column>
          <el-table-column prop="sellCount" label="销售数量"> </el-table-column>
          <el-table-column prop="countRate" label="数量比例"> </el-table-column>
          <el-table-column label="销售金额">
            <template slot-scope="scope">
              ￥{{ _pennyToYuan(scope.row.sellAmount) }}
            </template>
          </el-table-column>
          <el-table-column prop="amountRate" label="金额比例">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as goodStatistics from '@/api/goodStatistics'
import * as R from 'ramda'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      data: [],
      total: 10,
      pageSize: 8,
      currentPage: 1,
      numberChartSettings: {
        type: 'pie',
        radius: 100,
        legendLimit: 10,
        labelLine: {
          show: false
        },
        label: {
          show: false
        }
      },
      moneyChartSettings: {
        type: 'pie',
        radius: 100,
        legendLimit: 10,
        labelLine: {
          show: false
        },
        label: {
          show: false
        }
      },
      numberChartData: {
        columns: ['name', 'sellCount'],
        rows: []
      },
      moneyChartData: {
        columns: ['name', 'sellAmount'],
        rows: []
      },
      extend: {
        legend: {
          show: false
        },
        radius: ['40%', '70%']
      }
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    showData () {
      return R.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize,
        this.data
      )
    }
  },
  methods: {
    async fetchData (date = 30) {
      const res = await goodStatistics.plate(getDateRange(date))
      this.handleData(res)
    },
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.currentPage = val
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const res = await goodStatistics.plate(fmtDataRange(data))
      this.handleData(res)
    },
    handleData (data) {
      this.numberChartData.rows = R.clone(data).map(o =>
        R.pick(['name', 'sellCount'], o)
      )
      this.moneyChartData.rows = R.clone(data).map(o => {
        o.sellAmount = this._pennyToYuan(o.sellAmount)
        return R.pick(['name', 'sellAmount'], o)
      })
      this.data = data
      this.total = data.length
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #ffffff;
  height: 550px;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d9e0f0;

    span {
      color: #999999;
      margin-right: 20px;
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 3fr 3fr 4fr;
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7d7e8e;
      line-height: 25px;
    }
    border-right: 1px solid #d9e0f0;
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
  }
}
</style>
