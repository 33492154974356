<template>
  <div>
    <div class="summary">
      <div class="summary-item">
        <div class="summary-item-title">商品总数</div>
        <div class="summary-item-number">{{ summary.goodsCount }}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">在售商品</div>
        <div class="summary-item-number">{{ summary.onSaleCount }}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">下架商品</div>
        <div class="summary-item-number">{{ summary.offSaleCount }}</div>
      </div>
      <div class="summary-item" style="border: none;">
        <div class="summary-item-title">售磬商品</div>
        <div class="summary-item-number">{{ summary.sellOutCount }}</div>
      </div>
    </div>

    <Category />

    <Plate />

    <Stock />

    <Sale />

  </div>
</template>

<script>
import * as goodStatistics from '@/api/goodStatistics'
import Category from './category'
import Plate from './plate'
import Sale from './sale'
import Stock from './stock.vue'

export default {
  components: {
    Category,
    Plate,
    Sale,
    Stock
  },
  data () {
    return {
      summary: {
        goodsCount: 0,
        onSaleCount: 0,
        offSaleCount: 0,
        sellOutCount: 0
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const summary = await goodStatistics.summary({})
      this.summary = summary
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #FFFFFF;
  height: 140px;
  &-item{
    height: 100%;
    display: grid;
    align-content: center;
    text-align: center;
    border-right: 1px solid #D9E0F0;
    &-title{
      font-size: 16px;
      color: #7D7E8E;
    }
    &-number {
      font-size: 32px;
      font-weight: bold;
      color: #505162;
      line-height: 42px;
    }
  }
}

</style>
